html * {
    scrollbar-width: thin;
    scrollbar-color: $accent-color transparent;
}

/* Webkit */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Fond du scroll */
::-webkit-scrollbar-track {
    background: $accent-color;
    display: none
}

/* Barre du scroll */
::-webkit-scrollbar-thumb {
    background: $accent-color;

    &:hover {
        background-color: $primary-color;
    }
}