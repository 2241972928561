// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use 'sass:map';
@use '@angular/material'as mat;
// Plus imports for other components in your app.

$syn-font-family: Arial,
Helvetica,
sans-serif;

$font-size: 13px;

@include mat.core();

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$santynelle-primary: mat.define-palette(mat.$teal-palette, 800);
$santynelle-accent: mat.define-palette(mat.$teal-palette, A700);

// The warn palette is optional (defaults to red).
$santynelle-warn: mat.define-palette(mat.$red-palette, 800);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$santynelle-theme: mat.define-light-theme((color: (primary: $santynelle-primary,
            accent: $santynelle-accent,
            warn: $santynelle-warn,
        ),
        density:0));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($santynelle-theme);

$color-config: mat.get-color-config($santynelle-theme);
$primary-palette: map.get($color-config, 'primary');
$accent-palette: map.get($color-config, 'accent');
$warn-palette: map.get($color-config, 'warn');

$primary-color: mat.get-color-from-palette($primary-palette);
$accent-color: mat.get-color-from-palette($accent-palette);
$warn-color: mat.get-color-from-palette($warn-palette);
$white-color: white;
$info-color: #1976D2;
$alert-color: #FB8C00;