html,
body {
    height: 100%;
    overflow-x: hidden;
    margin: 0;
    font-family: $syn-font-family;
    font-size: $font-size;
    line-height: 1.5;
    letter-spacing: normal;

    * {
        outline: none !important;
    }
}

.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.selected {
    background-color: rgba($accent-color, 0.2);
}

.footer {
    background-color: $warn-color;
    color: $white-color;
    padding: 5px 3px 5px 3px;
    white-space: nowrap;
    overflow: hidden;
}

.content {
    flex: 1;
    overflow: auto;
    background: $white-color;
}

.information {
    color: $info-color;
    font-size: $font-size - 1;
    margin-bottom: 10px;
}

.smallFont {
    font-size: $font-size - 2;
}

.tWarn {
    color: $warn-color;
}

.tWhite {
    color: $white-color;
}

.tAccent {
    color: $accent-color;
}

.tPrimary {
    color: $primary-color;
}

.app-spacer {
    flex: 1 1 auto;
}

.pointer {
    cursor: pointer;
}

.smallVMargin {
    margin-top: 3px;
}

.smallHMargin {
    margin-left: 3px;
}

.btnBar {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
}

.fullH {
    height: 100%;
}

.fullW {
    width: 100%;
}

.center {
    text-align: center;
}

mat-sidenav.mat-sidenav {
    min-width: 200px;
}

mat-sidenav.mat-sidenav,
mat-sidenav-content.mat-sidenav-content {
    top: 100px;
}

.module-content {
    padding-bottom: 100px;
    padding-top: 10px;
    margin-left: 5px !important;
}

.single-content {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.mat-list-base .mat-list-item {
    font-size: $font-size;
}

mat-nav-list.mat-nav-list {
    .icon-unexpand {
        opacity: 0;
        visibility: hidden;
    }

    .mat-list-item:hover {
        background-color: transparent;
        color: $accent-color;
    }
}

.disabled {
    pointer-events: none !important;
    cursor: default;
    opacity: 0.5;
}

.iconState {
    color: $white-color;
    background-color: $primary-color;
}

.hidden {
    display: none;
    visibility: hidden;
}

.boldMenu {
    font-size: 17px;
    color: $primary-color;
}

.success-snack {
    background-color: $accent-color;
    color: $white-color;
}

.info-snack {
    background-color: $info-color;
    color: $white-color;
}

.warn-snack {
    background-color: $alert-color;
    color: $white-color;
}

.error-snack {
    background-color: $warn-color;
    color: $white-color;
}

.sl-content-ref {
    padding-right: 10px;
}

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.6);
}

.picture {
    border: 1px solid rgba($color: $warn-color, $alpha: 0.2);
}

.userPhoto {
    height: 20vh;
    width: 100%;
    max-width: 20vh;
}

.loading-text {
    top: 55% !important;
}

.relative {
    position: relative !important;
}

.smallIcon {
    height: 12px !important;
    width: 12px !important;
    font-size: 12px !important;
}

.placeholder {
    opacity: 0.7 !important;
}

.btnFlag {
    width: 24px;
    margin-right: 10px;
    cursor: pointer;
}

.syn-dialog-container {
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
}